<template>
  <div class="rootWrap">
    <div class="selectWrap">
      <el-select v-model="selectValue" placeholder="请选择" @change="handleChangeSelect" :popper-append-to-body="false">
        <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <!-- <el-button @click="handleClickBtn(false)">上一个</el-button>
      <el-button @click="handleClickBtn(true)">下一个</el-button> -->
    </div>

    <div id="echartsContainer"></div>
  </div>
</template>

<script>
import { ref, shallowRef, onMounted, onUnmounted, nextTick, defineComponent } from 'vue'
import axios from 'axios'
import * as echarts from 'echarts'
import { getItem } from '../api'
import { ElMessage } from 'element-plus'
// const unwarp = obj => obj && (obj.__v_raw || obj.valueOf() || obj)
export default defineComponent({
  setup() {
    const options = ref({})
    const dom = ref(null)
    let echartsInstance = shallowRef(null)
    let xData = ref([])
    let yData = ref([])
    let selectValue = ref('')
    let selectOptions = ref([])
    let url = ref('http://47.101.177.0:9000/portal/ropeWayDevice/test/queryList')
    let url1 = ref('http://47.101.177.0:9000/portal/ropeWayDevice/test/queryDetail')
    let index = ref(0)
    let indexList = ref([])

    onMounted(() => {
      initOptions()
      getSelectOptions()
      dom.value = document.getElementById('echartsContainer')
      echartsInstance.value = echarts.init(dom.value)
      window.addEventListener('resize', resize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })

    const resize = () => {
      echartsInstance.value.resize()
    }
    const initOptions = () => {
      options.value = {
        xAxis: {
          type: 'category',
          data: xData.value,
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          max: 50,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            dataZoom: {
              title: {
                zoom: '缩放',
                back: '还原'
              },
              yAxisIndex: false
            }
          }
        },
        series: [
          {
            data: yData.value,
            type: 'line',
            symbol: 'none'
          }
        ]
      }
    }
    // 下拉框改变事件
    const handleChangeSelect = value => {
      index.value = indexList.value.indexOf(value)
      updateDataToRender(value)
    }
    // 更新数据并重新渲染图
    const updateDataToRender = id => {
      getEchartsSoureData(id)
    }
    // 点击切换按钮事件
    const handleClickBtn = value => {
      if (!value) {
        if (selectValue.value && index.value == 0) {
          ElMessage({
            type: 'warning',
            message: '没有上一个了'
          })
        }
        index.value--
        if (index.value >= 0) {
          selectValue.value = selectOptions.value[index.value].value
          getEchartsSoureData(selectValue.value)
        } else {
          index.value = 0
        }
      } else {
        if (selectValue.value && index.value == indexList.value.length - 1) {
          ElMessage({
            type: 'warning',
            message: '没有下一个了'
          })
        }
        index.value++
        if (index.value <= indexList.value.length - 1) {
          selectValue.value = selectOptions.value[index.value].value
          getEchartsSoureData(selectValue.value)
        } else {
          index.value = indexList.value.length - 1
        }
      }
    }
    // 请求下拉框数据
    const getSelectOptions = async () => {
      const res = await axios.get(url.value)
      if (res.status == 200 && res.data.resultCode == '0000') {
        let data = res.data.data
        let cache = {}
        data.forEach(item => {
          cache = {}
          cache.label = new Date(item.startTime).toLocaleString().replaceAll('/', '-')
          cache.value = item.id
          selectOptions.value.push(cache)
          indexList.value.push(item.id)
        })
      }
    }
    // 请求图形原始数据
    const getEchartsSoureData = async id => {
      const res = await axios.post(url1.value, { id: id })
      if (res.status == 200 && res.data.resultCode == '0000') {
        let { result } = res.data.data
        yData.value = []
        xData.value = []
        // yData.value = result
        for (let i = 0; i < result.length; i++) {
          xData.value.push(i + 1)
          yData.value.push(result[i] / 10)
        }
        renderEcharts()
      }
    }
    // 渲染图
    const renderEcharts = () => {
      nextTick(() => {
        options.value.series[0].data = yData.value
        options.value.xAxis.data = xData.value
        if (echartsInstance.value) {
          echartsInstance.value.dispose()
          dom.value = document.getElementById('echartsContainer')
          echartsInstance.value = echarts.init(dom.value)
          echartsInstance.value.setOption(options.value)
        }
      })
    }
    return {
      handleChangeSelect,
      renderEcharts,
      getEchartsSoureData,
      getSelectOptions,
      handleClickBtn,
      updateDataToRender,
      handleChangeSelect,
      selectOptions,
      selectValue
    }
  }
})
</script>

<style lang="scss" scoped>
.rootWrap {
  width: 100%;
  height: 100%;
  .selectWrap {
    padding: 20px 0;
    .el-button {
      margin-left: 40px;
    }
    :deep(.selected) {
      background-color: #0099ff !important;
    }
  }

  #echartsContainer {
    width: 100%;
    height: 80%;
    background-color: #fff;
  }
}
</style>
